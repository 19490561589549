<template>
  <div>
    <breadCrumb :title="$t('suo-you-ke-fu')" :menuList="menuList"></breadCrumb>
    <div v-if="LiveChat">
      <LiveChatWidget
          :customerName="username"
          :customerEmail="username"
          :group="uid"
          :sessionVariables="session"
          :license="license"
          :visibility="maximized"
          v-on:visibility-changed="handleNewEvent"
          v-on:availability-changed="availabilityChanged"
          v-on:customer-status-changed="customerStatusChanged"
          v-on:form-submitted="formSubmitted"
          v-on:rating-submitted="ratingSubmitted"
          v-on:greeting-hidden="greetingHidden"
          v-on:greeting-displayed="greetingDisplayed"
      />
    </div>
    <section class="mb-2">
      <div class="container">
        <div class="row gutters-10 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1">

          <div class="col" v-for="(item, i) in dataList" :key="i" >
            <div class="row no-gutters bg-white align-items-center border border-light rounded hov-shadow-md mb-3 has-transition">
              <div class="col-4">
                <a  class="d-block p-3" tabindex="0">
                  <img  :src="item.Avatar" class="img-fluid lazyload" key="">
                </a>
              </div>
              <div class="col-8 border-left border-light">
                <div class="p-3 text-left">
                  <h2 class="h6 fw-600 text-truncate">
                    <a  class="text-reset" tabindex="0">{{ item.Name }}</a>
                  </h2>
                  <a class="btn btn-soft-primary btn-sm" href="javascript: void(0)" @click="toKefu(item.Type, item.Link)">
                    {{ $t('zai-xian-zi-xun') }} <i class="las la-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="aiz-pagination" v-if="page.total > 1">
            <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import pageBar from '@/components/page'
import  { kefuList } from '@/api/index'
import { LiveChatWidget } from '@livechat/widget-vue/v2'
import {getUser, getUserId} from "@/utils/auth";
export default {
  components: {
    breadCrumb,
    pageBar,
    LiveChatWidget
  },
  data() {
    return {
      lineUrl: require('../../assets/imgs/kefu2.png'),
      kefuUrl: require('../../assets/imgs/kefu1.png'),
      menuList: [
        {
          title: this.$t('suo-you-ke-fu-0'),
          path: 'kefu'
        },
      ],
      LiveChat: false,
      license: "",
      dataList: [],
      page: {
        page: 1,
        limit: 12,
        total: 1
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      kefuList().then(res => {
        console.log("kefu")
        console.log(res)
        for(var i in res.data.Items) {
          let data = res.data.Items[i]
          if(data.Type === 'meiqia') {
            this.meiqia()
          }
          if (data.Type === 'livechatinc') {
            this.LiveChat = true
            this.license = data.Link
          }
        }
      })
    },
    changePage(page) {
      this.page.page = page
      this.init()
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    meiqia(){
      let that = this
      _MEIQIA('withoutBtn');
      _MEIQIA('showPanel');
      // 传递顾客信息
      _MEIQIA('metadata', {
        ID: getUserId(), // 美洽默认字段
        name: getUser(), // 美洽默认字段
        username: getUser(), // 美洽默认字段
      });
      _MEIQIA('getPanelVisibility', function (visibility){
        console.log("customerList getPanelVisibility")
        if (visibility === 'visible') {
          // _MEIQIA('hidePanel');
        }
      });
    },
    toKefu(Type, Link) {
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else if (Type == 'meiqia') {
        this.meiqia()
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
            case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toKefuPath(Link)
      }
    },
    toKefuPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },
  }
}
</script>