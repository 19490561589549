<template>
  <section class="pt-4 mb-4">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-6 text-center text-lg-left">
          <h1 class="fw-600 h4">{{title}}</h1>
        </div>
        <div class="col-lg-6">
          <ul class="breadcrumb bg-transparent p-0 justify-content-center justify-content-lg-end">
            <li class="breadcrumb-item opacity-50">
              <a class="text-reset" @click="toPath('index')">{{ $t('shou-ye') }}</a>
            </li>
            <li class="text-dark fw-600 breadcrumb-item" v-for="(item, i) in menuList" :key="i">
              <a class="text-reset" >"{{item.title}}"</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'breadCrumb',
  components: {
  },
  props: {
    title: String,
    menuList: Array
  },
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    }
  }
}
</script>